import { useEffect, useState } from "react"
import Link from "next/link"
import styled from "styled-components"
import { Container } from "@/styled/Grid"
import { CallUsButton, SmallCallUsButton } from "@/styled/Buttons"
import { includes } from "lodash"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import Icon from "@/components/icons/icon"
import Burger from "@/components/icons/burger"
import Image from "next/image"
import useScreenSize from "@/hooks/useScreenSize"
import SideMenu from "./SideMenu"

const Wrapper = styled.div`
  width: 100%;
  top: -30px;
  padding-top: ${({ $dark }) => ($dark ? "15px" : "0")};
  padding-bottom: 0;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: -100px;
  transition: top 0.6s ease;
  && {
    overflow: visible;
  }
  &.floating {
    top: 0;
    position: fixed;
    z-index: 3;
    background: rgba(51, 51, 51, 0.9);
  }
`

const NavBar = styled.nav`
  background: transparent;
  position: relative;
  z-index: 2;
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  .floating & {
    height: 60px;
  }
`

const Logo = styled.div`
  display: inline-block;
`
const ImageWrapper = styled.div`
  position: relative;
`

const StyledImage = styled(Image)`
  position: relative !important;
  min-width: 100px;
`

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  li {
    display: inline-block;
  }
`
const MenuItem = styled.li`
  a {
    text-decoration: none;
    min-width: 100px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 5px 15px;
    color: ${({ theme, $dark }) => theme.colors[$dark ? "deepPink" : "white"]};
  }
`

const Phone = styled.div`
  float: right;
`

const MenuButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme, $dark }) => theme.colors[$dark ? "deepPink" : "white"]};
  &:focus {
    outline: none;
  }
`

export default function TopBar(props) {
  const { dark = false, phoneNumber, pageName } = props
  const { screenSize } = useScreenSize()
  const smallScreen = includes(["xs", "sm", "md"], screenSize)

  const [floating, setFloating] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => setIsOpen(!isOpen)

  const onClickCTA = () => {
    sendEvent("incoming call", { event_category: pageName, event_label: "incoming call header" })
  }

  useEffect(() => {
    if (dark) return
    const handleScroll = () => {
      setFloating(window.scrollY > 540)
    }
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <Wrapper $dark={+dark} className={floating ? "floating" : ""}>
      <Container className="p-0">
        <NavBar>
          {smallScreen && (
            <MenuButton $dark={dark} onClick={toggleMenu} aria-label="Menú móvil">
              <Icon Icon={Burger} className="d-block d-lg-none" />
            </MenuButton>
          )}
          <TopLogo dark={dark} />
          {!smallScreen && <Menu dark={dark} />}
          <Phone>
            <div className="d-none d-lg-block">
              <CallUsButton style={{ width: "260px" }} onClick={onClickCTA} phoneNumber={phoneNumber} />
            </div>
            <div className="d-block d-lg-none">
              <SmallCallUsButton
                $inputColor={dark ? "deepPink" : "white"}
                onClick={onClickCTA}
                phoneNumber={phoneNumber}
              />
            </div>
          </Phone>
        </NavBar>
        <SideMenu open={isOpen} onClose={() => setIsOpen(false)} />
      </Container>
    </Wrapper>
  )
}

function TopLogo(props) {
  const { dark, ...rest } = props
  return (
    <Logo {...rest}>
      <Link href="/" passHref>
        <ImageWrapper>
          <StyledImage
            alt="Logo Physiowow"
            src={`https://res.cloudinary.com/physio-wow/image/upload/w_150,q_auto,f_auto/static/logos/${
              dark ? "blue" : "white"
            }_logo`}
            fill
            priority
          />
        </ImageWrapper>
      </Link>
    </Logo>
  )
}

function Menu(props) {
  const { dark } = props
  return (
    <div>
      <MenuList className="d-none d-lg-block">
        <MenuItem $dark={dark}>
          <Link href="/tratamientos" passHref>
            Tratamientos
          </Link>
        </MenuItem>
        <MenuItem $dark={dark}>
          <Link href="/blog" passHref>
            Blog
          </Link>
        </MenuItem>
        <MenuItem $dark={dark}>
          <Link href="/donde-estamos" passHref>
            Dónde estamos
          </Link>
        </MenuItem>
        <MenuItem $dark={dark}>
          <Link href="/alquiler-magnetoterapia" passHref>
            Magnetoterapia
          </Link>
        </MenuItem>
      </MenuList>
    </div>
  )
}
