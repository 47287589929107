import React from "react"
import * as PropTypes from "prop-types"
import { useRouter } from "next/router"
import Head from "next/head"

function SEOData(props) {
  const { title = null, description = null, image = "blue_logo.png", noIndex = false } = props
  const router = useRouter()

  const href = `https://physiowow.com${router.asPath}`

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Facebook Meta Tags */}
      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="es_ES" />
      <meta
        property="og:image"
        content={`https://res.cloudinary.com/physio-wow/image/upload/w_auto,q_auto,f_auto/static/logos/${image}`}
      />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="physiowow.com" />
      <meta property="twitter:url" content={href} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content={`https://res.cloudinary.com/physio-wow/image/upload/w_auto,q_auto,f_auto/static/logos/${image}`}
      />

      <link rel="canonical" href={href} />
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  )
}

SEOData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  noIndex: PropTypes.bool
}

export default SEOData
