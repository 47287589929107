import styled from "styled-components"

const defaultCss = `
  margin: 0;
  letter-spacing: 0;
  display: block;
`

const Text = styled.p`
  ${defaultCss}
  color: ${({ $txtColor, theme }) => ($txtColor ? theme.colors[$txtColor] : null)};
  font-weight: ${({ $weight, theme }) => theme.weights[$weight]};
  ${({ $variant }) => styleMapSM[$variant]}
  ${({ $center }) => ($center ? "text-align: center;" : null)}
  @media ${({ theme }) => theme.breakpoints.lg} {
    ${({ $variant }) => styleMap[$variant]}
  }
`

const styleMapSM = {
  title1: `
    font-size: 40px;
    line-height: 44px;
  `,
  title2: `
    font-size: 27px;
    line-height: 36px;
  `,
  title3: `
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 25px;
  `,
  title4: `
    font-size: 18px;
    line-height: 24px;
  `,
  label: `
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  `,
  smallPrice: `
    font-size: 13px;
    letter-spacing: -0,2px;
    line-height: 18px;
  `,
  paragraph: `
    font-size: 18px;
    line-height: 20px;
  `,
  small: `
    font-size: 14px;
    line-height: 22px;
  `
}

const styleMap = {
  title1: `
    font-size: 42px;
    line-height: 46px;
  `,
  title2: `
    font-size: 36px;
    line-height: 46px;
  `,
  title3: `
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 25px;
  `,
  title4: `
    font-size: 18px;
    line-height: 24px;
  `,
  label: `
    font-size: 24px;
    line-height: 36px;
  `,
  smallPrice: `
    font-size: 13px;
    letter-spacing: -0,2px;
    line-height: 18px;
  `,
  paragraph: `
    font-size: 22px;
    line-height: 32px;
  `,
  small: `
    font-size: 16px;
    line-height: 26px;
  `
}

const variantMap = {
  title1: "h1",
  title2: "h2",
  title3: "h3",
  title4: "h4",
  label: "p",
  smallPrice: "p",
  paragraph: "p",
  small: "small"
}

export default function Typography(props) {
  const {
    el = null,
    variant = "paragraph",
    $weight = "regular",
    children,
    $txtColor = "darkBlue",
    center = false,
    ...rest
  } = props

  return (
    <Text
      as={el || variantMap[variant]}
      $variant={variant}
      $weight={$weight}
      $center={center}
      $txtColor={$txtColor}
      {...rest}
    >
      {children}
    </Text>
  )
}
